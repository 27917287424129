<template>
    <q-card style="width: 600px; max-width: 60vw">
        <q-bar class="bg-primary text-white">
            <q-icon name="fas fa-keyboard" />
            <div>
                外部链接
                <small class="q-ml-sm text-size-sm">
                    {{ TgyBaseLinkEntity.LinkName }}({{
                            TgyBaseLinkEntity.LinkID
                    }})
                </small>
            </div>

            <q-space />

            <q-btn dense flat icon="close" v-close-popup>
                <q-tooltip>关闭</q-tooltip>
            </q-btn>
        </q-bar>

        <q-card-section class="q-pt-none" style="max-height:80vh;overflow-y:auto">
            <q-form ref="form">
                <q-input label="链接名称"  v-model="TgyBaseLinkEntity.LinkName" />
                <q-input label="链接地址"  v-model="TgyBaseLinkEntity.LinkAddress" />
            </q-form>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
            <q-btn flat label="取消" v-close-popup />
            <q-btn flat label="确定" @click="onOK" />
        </q-card-actions>
    </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("TgyBaseLink", [
            "TgyBaseLinkShowDlg",
            "TgyBaseLinkEntity",
            "TgyBaseLinkList",
        ]),
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("TgyBaseLink", ["actUpdateTgyBaseLink", "actLoadTgyBaseLinkList"]),
        onOK() {
            this.$refs.form.validate().then((success) => {
                if (success) {
                    this.actUpdateTgyBaseLink({
                        HospID: this.$store.getters["appUser/hospID"],
                        CUser: this.$store.getters["appUser/userID"],
                        Data: this.TgyBaseLinkEntity,
                    }).then((res) => {
                        if (res.Code == 0) {
                            this.$emit("onLoadData");
                            this.msg.success(res.Message);
                        } else {
                            this.msg.error(res.Message);
                        }
                    });
                }
            });
        },
    },
};
</script>
